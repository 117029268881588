<template>
  <a-modal
    title="Редактировать сотрудника"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-tooltip placement="top">
        <template #title>Отправить по СМС ссылку на привязку к telegram-боту</template>
        <a-button @click="invite" key="confirm" type="link">SMS с ссылкой на бот</a-button>
        <a-tooltip placement="top" class="mx-2">
          <template #title>Отправить приглашене</template>
        </a-tooltip>
      </a-tooltip>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="update">ОК</a-button>
    </template>
    <a-form :model="employee" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="spin-loader" v-if="fetching">
        <a-spin />
      </div>
      <div v-else>
        <a-upload
          name="photo_url"
          list-type="picture-card"
          class="photo-uploader"
          :show-upload-list="false"
          :headers="getHeaders()"
          :action="getUrl"
          :before-upload="uploadValidation"
          @change="uploadImage"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Фото</div>
          </div>
        </a-upload>
        <div class="mb-4 text-center" v-if="imageUrl">
          <a-button @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить фотографию</a-button>
        </div>
        <a-form-item label="Имя сотрудника">
          <a-input
            ref="employeeName"
            placeholder="Введите имя пользователя"
            v-model:value="employee.name" />
        </a-form-item>
        <a-form-item label="Описание">
          <a-input
            placeholder="Введите описание"
            v-model:value="employee.target_text" />
        </a-form-item>
        <a-form-item label="Номер телефона">
          <a-input
            @input="updatePhone($event.target.value)"
            :value="phone"
            v-maska="'+998(##) ###-##-##'"
            placeholder="Введите номер телефона"
          />
        </a-form-item>
        <a-form-item label="Роль">
          <a-select
            ref="role_id"
            v-model:value="employee.role_id"
            :options="roles"
            :option-filter-prop="'label'"
            placeholder="Выберите роль"
            show-search
          >
          </a-select>
        </a-form-item>
<!--        <a-form-item label="Доступ">-->
<!--          <a-radio-group v-model:value="employee.role" @change="onChangeAccess" button-style="solid" size="small">-->
<!--            <a-radio-button value="employee">Сотрудник</a-radio-button>-->
<!--            <a-radio-button value="manager">Менеджер</a-radio-button>-->
<!--            <a-radio-button value="driver">Водитель</a-radio-button>-->
<!--          </a-radio-group>-->
<!--        </a-form-item>-->
        <a-form-item label="Филиал">
          <a-select
            ref="store_id"
            show-search
            placeholder="Выберите филиал"
            v-model:value="employee.store_id"
            :options="stores"
            :filter-option="filter"
            @change="onChangeStore"
          />
        </a-form-item>
        <a-form-item :label="is_jowi ? 'ID сотрудника' : 'Привязка к POS'">
          <div v-if="!is_jowi" class="d-flex align-items-center">
            <a-select
              :disabled="loadingEmployees || employeeSelect"
              ref="pos_id"
              :loading="loadingEmployees"
              show-search
              placeholder="Выберите сотрудника"
              v-model:value="employee.pos_id"
              :options="employees"
              :filter-option="filter"
            />
            <a-button @click="refreshEmployee" class="ml-1">
              <i class="fe fe-refresh-ccw" />
            </a-button>
          </div>
          <a-input
            :disabled="loadingEmployees"
            v-else
            ref="pos_id"
            :loading="loadingEmployees"
            placeholder="Введите ID сотрудника"
            v-model:value="employee.pos_id"
          />
        </a-form-item>
        <a-form-item label="Показать в списке">
          <a-switch
            checked-children="Вкл"
            un-checked-children="Выкл"
            v-model:checked="employee.show_in_list" />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { LoadingOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import {ref, reactive, toRaw, nextTick} from "vue";
import { getHeaders } from "@/helpers/header"
import { getBase64 } from "@/helpers/base64";
import { message } from "ant-design-vue";
import apiClient from "@/services/axios";
import Employee from "@/models/Employee";
import { mask } from "maska";

const props = defineProps({
  employeeId: {
    type: Number,
    default: 0,
  },
  stores: {
    type: Object,
    default() {
      return {}
    },
  },
  roles: {
    type: Object,
    default() {
      return {};
    },
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let imageUrl = ref(''),
  is_jowi = ref(false),
  fetching = ref(true),
  loading = ref(false),
  loadingEmployees = ref(false),
  confirmLoading = ref(false),
  employeeSelect = ref(false),
  modalVisible = ref(true),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  phone = ref(''),
  employees = ref([]),
  employeeName = ref("employeeName"),
  employee = reactive(new Employee({}));

const
  getEmployees = storeId => {
    loadingEmployees.value = true
    employee.pos_id = null
    apiClient.get(`/store/employees?store=${storeId}`).then(response => {
      if (response.data.data) {
        employees.value = response.data.data['employees']
        is_jowi.value = response.data.data['pos_type_name']
          ? response.data.data['pos_type_name'].toLowerCase() === 'jowi'
          : false
      } else {
        employees.value = []
        is_jowi.value = false
      }
      loadingEmployees.value = false
    }).catch(() => {
      // console.log(err)
      loadingEmployees.value = false
    }).finally(() => {
      loadingEmployees.value = false
    })
  },
  onChangeStore = value => {
    employee.store_id = value;
    employeeSelect.value = true;
    refreshEmployee()
  },
  refreshEmployee = () => {
    getEmployees(employee.store_id)
    employeeSelect.value = false;
  },
  filter = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      employee.photo_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    imageUrl.value = ''
    employee.photo_url = ''

    confirmLoading.value = true;
    apiClient
      .put(`/employee/update?id=${props.employeeId}`, { photo_url: '' })
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  uploadValidation = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/employee/upload'
  },
  updatePhone = (input) => {
    let x = input
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    phone.value = input
    employee.phone = x[0];
  },
  resetForm = () => {
    phone.value = '';
    Object.assign(employee, new Employee({}));
    modalVisible.value = false;
    employeeSelect.value = true;
    emit('hide-modal')
  },
  invite = () => {
    confirmLoading.value = true;
    return apiClient.post(`/employee/invite?id=${props.employeeId}`).then(response => {
      confirmLoading.value = false;
      if (response) {
        let phone = (response.data && response.data.data) ? response.data.data : ''
        let formattedPhone = mask(phone, '+### ## ###-##-##')
        message.success(`Ссылка на привязку к telegram-боту отправлена на номер ${formattedPhone}`, 5)
      }
    }).catch(() => {
      confirmLoading.value = false;
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(employee)

    return apiClient.put(`/employee/update?id=${props.employeeId}`, model).then(response => {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      modalVisible = false;
      confirmLoading.value = false;
      emit('reload')
      emit('hide-modal')
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

apiClient.get(`/employee/${props.employeeId}`).then(response => {
  if (response) {
    employees.value = response.data.data?.employees

    let model = new Employee(response.data.data.employee)

    if (model.pos_id) {
      getEmployees(model.store_id)
    }
    updatePhone(model.phone)
    imageUrl.value = model.photo_url
    model.store_id = model.store_id.toString()
    model.show_in_list = model.show_in_list === 1

    if (response.data.data?.employee?.store?.['pos_type_name']?.toLowerCase() === 'jowi') {
      is_jowi.value = true
    }
    Object.assign(employee, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  fetching.value = false;
  nextTick(() => employeeName.value?.focus())
})

</script>

<style scoped>
@import "./edit.css";
</style>
